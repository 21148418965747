import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { CloseButton, PlayButton } from '../Icons/icons';
import { Title, Text } from '../Typography';
import { GlobalStyle, ModelContainer, TitleWrapper, Close, CloseBtn } from './styles';

export interface IModalBoxProps {
  colorScheme?: string;
  videoID?: string;
  playButton?: JSX.Element;
  title?: string;
  description?: string;
  btnClassName?: string;
}

const modelContainerStyle = 'bg-white h-4/5 rounded-2xl overflow-hidden modal-main';

export const ModalBox = ({
  videoID,
  colorScheme,
  playButton,
  title,
  description,
  btnClassName = ''
}: IModalBoxProps) => {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [Player, setPlayer] = useState<any>(null);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  function openModal() {
    setIsOpen(true);
  }

  const mounted = () => {
    return import('react-youtube').then((module) => {
      return module.default;
    });
  };

  const onPlayerReady = (event: any) => {
    event.target.mute();
    event.target.playVideo();
  };

  useEffect(() => {
    if (modalIsOpen && !isMounted) {
      mounted().then((Module) => {
        setPlayer(
          <Module
            videoId={videoID}
            opts={{
              width: '100%',
              height: '100%',
              playerVars: {
                autoplay: 1,
                playsinline: 1,
              },
            }}
            onReady={onPlayerReady}
          />,
        );
        setIsMounted(true);
      });
    }
  }, [modalIsOpen]);

  const closeModal = () => setIsOpen(false);

  const closeButton = (
    <CloseBtn className="mx-auto inline-block">
      <CloseButton />
    </CloseBtn>
  );
  const modalClassNames = `outline-none sm:w-11/12 md:w-10/12 md:h-modalVideoDesktop sm:h-modalVideoMobile
  transform md:translate-x-12 sm:translate-x-7 md:translate-y-4 xl:translate-y-8 3xl:translate-y-10 sm:-translate-y-20 ${
    title ? `sm:mt-5` : `sm:mt-10`
  } md:mt-0`;

  return (
    <div> 
      <GlobalStyle />
      <button onClick={openModal} type="button" className={btnClassName}>
        {playButton || <PlayButton colorScheme={colorScheme} />}
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        className={modalClassNames}
      >
        <TitleWrapper className="flex justify-between items-start sm:flex-col md:flex-row">
          <Close
            onClick={closeModal}
            className="cursor-default modal-close sm:block md:hidden w-full text-right pb-5"
          >
            {closeButton}
          </Close>
          {title && (
            <Title className="inline-block text-white" tag="p" type="sm">
              {title}
            </Title>
          )}
          <Close
            onClick={closeModal}
            className={`cursor-default modal-close sm:hidden md:inline-block ${
              !title && 'w-full text-right'
            }`}
          >
            {closeButton}
          </Close>
        </TitleWrapper>

        <ModelContainer className={modelContainerStyle}>{isMounted && Player}</ModelContainer>
        {description && (
          <Text className="text-white pt-5 pb-1" tag="p" type="md">
            {description}
          </Text>
        )}
      </Modal>
    </div>
  );
};
ModalBox.defaultProps = {
  videoID: '',
  colorScheme: 'Grey',
  playButton: '',
};
